import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import { Link as ScrollLink } from 'react-scroll';

import './header.css'

const Header = ({ siteTitle }) => (
  <div className="Header">
    <div>
      <h1>
        <ScrollLink className="Title" activeClass="active" to="image" href="#" spy={true} smooth={true} offset={-110} duration={500}>
          Sophia Zamaria
        </ScrollLink>
      </h1>
    </div>
    <div className="Sublinks">
      <ScrollLink className="Sublink" activeClass="active" to="about" href="#about" spy={true} smooth={true} offset={-110} duration={500}>
        About
      </ScrollLink>
      <ScrollLink className="Sublink" activeClass="active" to="cv" href="#cv" spy={true} smooth={true} offset={-110} duration={500}>
        CV
      </ScrollLink>
      <ScrollLink className="Sublink" activeClass="active" to="research" href="#research" spy={true} smooth={true} offset={-110} duration={500}>
        Research Interests
      </ScrollLink>
      <ScrollLink className="Sublink" activeClass="active" to="publications" href="#publications" spy={true} smooth={true} offset={-110} duration={500}>
        Publications
      </ScrollLink>
      <ScrollLink className="Sublink" activeClass="active" to="contact" href="#contact" spy={true} smooth={true} offset={-110} duration={500}>
        Contact
      </ScrollLink>
    </div>
  </div>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
